import React from 'react';
import { Box, Container, Grid, TextField, Button, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const ContactUsFooter = () => {
    const gradientBg = `radial-gradient(circle at 100% 100%, #2E5EB8 0%, 17.5%, rgba(46,94,184,0) 35%),
                      radial-gradient(circle at 0% 0%, #2E5EB8 0%, 11.55%, rgba(46,94,184,0) 35%),
                      radial-gradient(circle at 38.8888963063558% 56.38894399007162%, #0F1F3C 0%, 89%, rgba(15,31,60,0) 100%),
                      radial-gradient(circle at 98.52778116861978% 5.722249746322632%, #F5D149 0%, 58.28%, rgba(245,209,73,0) 94%),
                      radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)`;

    return (
        <Box sx={{ bgcolor: 'common.white', width: '100%', pt: 20, pb: 15, backgroundImage: gradientBg }}>
            <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: "#E6E6E6",
                        borderRadius: "20px",
                        color: "#0F1E3C"
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography
                                variant="h2"
                                component="span"
                                gutterBottom
                                fontWeight="bold"
                            >Contact Us</Typography>
                            <Typography variant="body1" gutterBottom>Feel free to contact us any time. We will get back to you as soon as we can!</Typography>

                            <form noValidate autoComplete="off">
                                <TextField fullWidth margin="normal" label="Name" />
                                <TextField fullWidth margin="normal" label="Email" />
                                <TextField fullWidth margin="normal" label="Message" multiline rows={4} />
                                <Button variant="contained" color="secondary" sx={{ mt: 3 }}>Send</Button>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>Info</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                                <MailOutlineIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">info@estreal.ge</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                                <PhoneIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">+995 123 45 67 89</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                                <LocationOnIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">Tbilisi, GEO</Typography>
                            </Box>
                            <Box sx={{ '& > svg': { mx: 0.5 } }}>
                                <FacebookIcon />
                                <InstagramIcon />
                                <LinkedInIcon />
                                <TwitterIcon />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ContactUsFooter;

// MapInput.js
import React from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { TextField, Box } from '@mui/material';

// Fix Leaflet icons (optional but recommended)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Component to handle map clicks and update marker position
function LocationMarker({ setCoordinates }) {
    const [position, setPosition] = React.useState(null);

    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            setPosition([lat, lng]);
            setCoordinates([lat, lng]);
        },
    });

    return position === null ? null : <Marker position={position} />;
}

// Main Map Input Component
export default function MapInput({ coordinates, setCoordinates }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            {/* Leaflet Map */}
            <MapContainer
                center={coordinates}
                zoom={10}
                style={{ height: '400px', width: '100%' }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker setCoordinates={setCoordinates} />
            </MapContainer>

            {/* MUI TextField to Display Selected Coordinates */}
            <TextField
                label="Selected Coordinates"
                variant="standard"
                disabled={true}
                fullWidth
                value={`Latitude: ${coordinates[0]}, Longitude: ${coordinates[1]}`}
                InputProps={{
                    readOnly: true,
                }}
            />
        </Box>
    );
}

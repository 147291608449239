import React, {useEffect, useState} from 'react';
import contentService from "../../../services/contentService";
import "../../../assets/css/MarkerPopup.css";
import {Box} from "@mui/material";

const MarkerPopup = ({listing}) => {
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [kpiData, setKpiData] = useState(null);
    const [loading, setLoading] = useState(true);

    const formatCurrency = (value) => new Intl.NumberFormat("en-US", {
        style: "currency", currency: "USD"
    }).format(value);
    const formatPercentage = (value) => `${value.toFixed(2)}%`;

    const difference = listing.objective_price - listing.price;
    const percentageDifference = (difference / listing.price) * 100;

    let barPosition;
    if (percentageDifference > 100) barPosition = '100%'; else if (percentageDifference < -100) barPosition = '0%'; else barPosition = `${50 + percentageDifference / 2}%`;

    useEffect(() => {
        // Get listing redcat_id and query its projected costs
        const getProjectedCosts = async () => {
            const response = await contentService.getProjectedCosts(listing.redcat_id);

            if (response.success) {
                setKpiData(response.data);
            }

            setLoading(false);
        }
        getProjectedCosts();
    }, []);

    return (<div className="marker-popup">
        <div className="header">
            <button className="redcat-id">{listing.redcat_id}</button>
            <button className="bookmark">★</button>
        </div>

        {listing.objective_price != null ? (<>
            <div className="price-difference">
                Price Difference: <span>{formatPercentage(percentageDifference)}</span>
            </div>
            <div className="price-bar">
                <div className="price-indicator" style={{left: barPosition}}></div>
            </div>
        </>) : (<div className="not-analyzed">Not Analyzed Yet</div>)}

        <div className="price-info">
            <div>Ask Price: <span>{formatCurrency(listing.price)}</span></div>
            {listing.objective_price != null && (
                <div>Analyzed Price: <span>{formatCurrency(listing.objective_price)}</span></div>)}
        </div>

        {!loading && kpiData && (<>
                <div className="property-info">
                    <div><i className="icon">📏</i> Size: <span>{kpiData.area} sqm</span></div>
                    <div><i className="icon">📈</i> 5 Year
                        Growth: <span>{formatPercentage(kpiData["5_year_growth_rate"])}</span></div>
                    <div><i className="icon">💰</i> 5 Year
                        Price: <span>{formatCurrency(kpiData["5_year_price"])}</span></div>
                    <div><i className="icon">🏖️</i> Monthly
                        Rental: <span>{formatCurrency(kpiData.short_term_rental_income_monthly)}</span></div>
                </div>

                <div className="projected-costs">
                    <h4>Projected Costs:</h4>
                    <div><i
                        className="icon">⚡</i> Utility: <span>{formatCurrency(kpiData.utility)}</span>
                    </div>
                    <div>
                        <i className="icon">🧹</i> Cleaning: <span>{formatCurrency(kpiData.cleaning)}</span>
                    </div>
                    <div><i
                        className="icon">🛡️</i> Insurance: <span>{formatCurrency(kpiData.insurance)}</span>
                    </div>
                    <div className="total">
                        <i className="icon">💵</i> Total: <span>{formatCurrency(kpiData.utility + kpiData.cleaning + kpiData.insurance)}</span>
                    </div>
                </div>

                <button className="info-button" onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                    {showAdditionalInfo ? 'Hide Info' : 'Show Additional Info'}
                </button>

            {showAdditionalInfo && (<div className="additional-info">
                For the calculation of taxes, management, and mortgage expenses, please inform estreal to
                update your account with the appropriate information for the AI to consider and calculate.
            </div>)}
            </>
        )}

        {!loading && !kpiData && (
            <Box textAlign="center">
                There is no KPI data available for this listing.
            </Box>
        )}
    </div>);
};

export default MarkerPopup;
import React, {useState} from 'react';
import {TextField, Checkbox, FormControlLabel, Button, Grid, Typography, Box, Divider} from '@mui/material';
import MapInput from "../../components/forms/mapInput";
import contentService from "../../services/contentService";

import {toast} from "react-toastify";
// Constants
const TransactionType = {
    SALE: ["SALE", "Sale"],
    LEASE: ["LEASE", "Lease"],
    LEASEHOLD_MORTGAGE: ["LEASEHOLD_MORTGAGE", "Leasehold Mortgage"],
    RENT: ["RENT", "Rent"],
    DAY_RENT: ["DAY_RENT", "Daily Rent"],
};

const PropertyType = {
    APARTMENT: ["APARTMENT", "Apartment"],
    HOUSE: ["HOUSE", "House"],
    HOTEL: ["HOTEL", "Hotel"],
    LAND: ["LAND", "Land"],
    COMMERCIAL: ["COMMERCIAL", "Commercial Property"],
};


const AmenityType = {
    BOOLEAN: "BOOLEAN",
    CHOICE: "CHOICE",
    COUNTABLE: "COUNTABLE",
    MEASURABLE: "MEASURABLE",
};

const Amenity = {
    AREA_LOGGIA: ["AREA_LOGGIA", AmenityType.MEASURABLE, "Loggia Area"],
    AREA_VERANDA: ["AREA_VERANDA", AmenityType.MEASURABLE, "Veranda Area"],
    AREA_BALCONY: ["AREA_BALCONY", AmenityType.MEASURABLE, "Balcony Area"],
    AREA_YARD: ["AREA_YARD", AmenityType.MEASURABLE, "Yard Area"],
    HEIGHT_CEILING: ["HEIGHT_CEILING", AmenityType.MEASURABLE, "Ceiling Height"],
    FRAME_BLACK: ["FRAME_BLACK", AmenityType.CHOICE, "Black Frame"],
    FRAME_WHITE: ["FRAME_WHITE", AmenityType.CHOICE, "White Frame"],
    FRAME_GREEN: ["FRAME_GREEN", AmenityType.CHOICE, "Green Frame"],
    PROJECT_CZECH: ["PROJECT_CZECH", AmenityType.CHOICE, "Czech Project"],
    PROJECT_KAVLASHVILI: ["PROJECT_KAVLASHVILI", AmenityType.CHOICE, "Kavlashvili Project"],
    PROJECT_KHRUSHCHOV: ["PROJECT_KHRUSHCHOV", AmenityType.CHOICE, "Khrushchov Project"],
    PROJECT_LVOV: ["PROJECT_LVOV", AmenityType.CHOICE, "Lvov Project"],
    PROJECT_MOSCOW: ["PROJECT_MOSCOW", AmenityType.CHOICE, "Moscow Project"],
    PROJECT_TUKHARELI: ["PROJECT_TUKHARELI", AmenityType.CHOICE, "Tukhareli Project"],
    PROJECT_URBAN: ["PROJECT_URBAN", AmenityType.CHOICE, "Urban Project"],
    RENOVATION_NEW: ["RENOVATION_NEW", AmenityType.CHOICE, "New Renovation"],
    RENOVATION_NONSTANDARD: ["RENOVATION_NONSTANDARD", AmenityType.CHOICE, "Non-Standard Renovation"],
    RENOVATION_NOT: ["RENOVATION_NOT", AmenityType.CHOICE, "No Renovation"],
    RENOVATION_OLD: ["RENOVATION_OLD", AmenityType.CHOICE, "Old Renovation"],
    RENOVATION_ONGOING: ["RENOVATION_ONGOING", AmenityType.CHOICE, "Ongoing Renovation"],
    ROOM_BATHROOM: ["ROOM_BATHROOM", AmenityType.COUNTABLE, "Bathrooms"],
    ROOM_BEDROOM: ["ROOM_BEDROOM", AmenityType.COUNTABLE, "Bedrooms"],
    ROOM_STOREROOM: ["ROOM_STOREROOM", AmenityType.COUNTABLE, "Storerooms"],
    ROOM_TOTAL: ["ROOM_TOTAL", AmenityType.COUNTABLE, "Total Rooms"],
    FLOOR_CURRENT: ["FLOOR_CURRENT", AmenityType.COUNTABLE, "Current Floor"],
    FLOOR_TOTAL: ["FLOOR_TOTAL", AmenityType.COUNTABLE, "Total Floors"],
    ELEVATOR_PASSENGER: ["ELEVATOR_PASSENGER", AmenityType.BOOLEAN, "Passenger Elevator"],
    ELEVATOR_SERVICE: ["ELEVATOR_SERVICE", AmenityType.BOOLEAN, "Service Elevator"],
    BASIC_INTERNET: ["BASIC_INTERNET", AmenityType.BOOLEAN, "Internet"],
    BASIC_ELECTRICITY: ["BASIC_ELECTRICITY", AmenityType.BOOLEAN, "Electricity"],
    BASIC_SEWAGE: ["BASIC_SEWAGE", AmenityType.BOOLEAN, "Sewage"],
    BASIC_TELEPHONE: ["BASIC_TELEPHONE", AmenityType.BOOLEAN, "Telephone"],
    BASIC_TELEVISION: ["BASIC_TELEVISION", AmenityType.BOOLEAN, "Television"],
    BASIC_WATER: ["BASIC_WATER", AmenityType.BOOLEAN, "Water Supply"],
    BASIC_PROPANE: ["BASIC_PROPANE", AmenityType.BOOLEAN, "Propane Supply"],
    BASIC_FURNITURE: ["BASIC_FURNITURE", AmenityType.BOOLEAN, "Furniture"],
    HVAC_WATERHEATER: ["HVAC_WATERHEATER", AmenityType.BOOLEAN, "Water Heater"],
    HVAC_CONDITIONER: ["HVAC_CONDITIONER", AmenityType.BOOLEAN, "Air Conditioner"],
    HVAC_FIREPLACE: ["HVAC_FIREPLACE", AmenityType.BOOLEAN, "Fireplace"],
    HVAC_PROPANE: ["HVAC_PROPANE", AmenityType.BOOLEAN, "Propane Heating"],
    EXTERNAL_PARKING: ["EXTERNAL_PARKING", AmenityType.BOOLEAN, "Parking"],
    EXTERNAL_PWD: ["EXTERNAL_PWD", AmenityType.BOOLEAN, "Accessible for Disabled"],
    EXTERNAL_ASPHALT: ["EXTERNAL_ASPHALT", AmenityType.BOOLEAN, "Asphalt Road"],
    EXTERNAL_ITALIAN: ["EXTERNAL_ITALIAN", AmenityType.BOOLEAN, "Italian Courtyard"],
};

const sectionDisplayNames = {
    AREA: {
        displayName: "Additional Areas",
        amenities: [
            Amenity.AREA_LOGGIA,
            Amenity.AREA_VERANDA,
            Amenity.AREA_BALCONY,
            Amenity.AREA_YARD,
            Amenity.HEIGHT_CEILING
        ]
    },
    FRAME: {
        displayName: "Interior Condition",
        amenities: [
            Amenity.FRAME_BLACK,
            Amenity.FRAME_WHITE,
            Amenity.FRAME_GREEN
        ]
    },
    PROJECT: {
        displayName: "Project",
        amenities: [
            Amenity.PROJECT_CZECH,
            Amenity.PROJECT_KAVLASHVILI,
            Amenity.PROJECT_KHRUSHCHOV,
            Amenity.PROJECT_LVOV,
            Amenity.PROJECT_MOSCOW,
            Amenity.PROJECT_TUKHARELI,
            Amenity.PROJECT_URBAN
        ]
    },
    RENOVATION: {
        displayName: "Renovation Status",
        amenities: [
            Amenity.RENOVATION_NEW,
            Amenity.RENOVATION_NONSTANDARD,
            Amenity.RENOVATION_NOT,
            Amenity.RENOVATION_OLD,
            Amenity.RENOVATION_ONGOING
        ]
    },
    ROOM: {
        displayName: "Room Information",
        amenities: [
            Amenity.ROOM_BATHROOM,
            Amenity.ROOM_BEDROOM,
            Amenity.ROOM_STOREROOM,
            Amenity.ROOM_TOTAL
        ]
    },
    FLOOR: {
        displayName: "Floor Information",
        amenities: [
            Amenity.FLOOR_CURRENT,
            Amenity.FLOOR_TOTAL
        ]
    },
    ELEVATOR: {
        displayName: "Elevator Types",
        amenities: [
            Amenity.ELEVATOR_PASSENGER,
            Amenity.ELEVATOR_SERVICE
        ]
    },
    BASIC: {
        displayName: "Basic Amenities",
        amenities: [
            Amenity.BASIC_INTERNET,
            Amenity.BASIC_ELECTRICITY,
            Amenity.BASIC_SEWAGE,
            Amenity.BASIC_TELEPHONE,
            Amenity.BASIC_TELEVISION,
            Amenity.BASIC_WATER,
            Amenity.BASIC_PROPANE,
            Amenity.BASIC_FURNITURE
        ]
    },
    HVAC: {
        displayName: "Heating, Ventilation, and Cooling",
        amenities: [
            Amenity.HVAC_WATERHEATER,
            Amenity.HVAC_CONDITIONER,
            Amenity.HVAC_FIREPLACE,
            Amenity.HVAC_PROPANE
        ]
    },
    EXTERNAL: {
        displayName: "External Features",
        amenities: [
            Amenity.EXTERNAL_PARKING,
            Amenity.EXTERNAL_PWD,
            Amenity.EXTERNAL_ASPHALT,
            Amenity.EXTERNAL_ITALIAN
        ]
    }
};


// Main Component
const CalculatePage = () => {
    const [loading, setLoading] = useState(false);
    const [resultPrice, setResultPrice] = useState(null);
    const [formData, setFormData] = useState({
        redcat_id: null,
        transaction_type: "",
        property_type: "",
        price: "",
        area: "",
        location: [41.724689, 44.839681],
        location_geohash: null,
        region: {
            "name": "DINAMO",
            "weight": 1
        },
        country: "GE",
        address: null,
        amenities: {},
    });

    const [checkedAmenities, setCheckedAmenities] = useState({});
    const [amenityValues, setAmenityValues] = useState({});

    // Handle input changes
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleLocationChange = (coordinates) => {
        setFormData({...formData, location: coordinates});
    }

    const handleAmenityChange = (amenityName, amenityType, event) => {
        const checked = event.target.checked;

        // Update the checked state for the amenity
        setCheckedAmenities({...checkedAmenities, [amenityName]: checked});

        if (!checked) {
            // Remove the amenity from formData and amenityValues when unchecked
            const newAmenityValues = {...amenityValues};
            delete newAmenityValues[amenityName];
            setAmenityValues(newAmenityValues);

            const newCheckedAmenities = {...checkedAmenities};
            delete newCheckedAmenities[amenityName];
            setCheckedAmenities(newCheckedAmenities);

            // Remove the amenity from formData
            const newAmenities = {...formData.amenities};
            delete newAmenities[amenityName];
            setFormData({
                ...formData,
                amenities: newAmenities,
            });
        } else {
            // Update formData based on amenity type
            setFormData({
                ...formData,
                amenities: {
                    ...formData.amenities,
                    [amenityName]: amenityType === AmenityType.BOOLEAN || amenityType === AmenityType.CHOICE ? 0 : "0.0", // Set empty string for countable amenities initially
                },
            });
        }
    };

    const handleAmenityValueChange = (amenityName, value) => {
        // If the value is countable/measurable, convert it to a float string
        const floatValue = parseFloat(value).toString();

        setAmenityValues({...amenityValues, [amenityName]: floatValue});

        // Update formData with the float string for measurable amenities
        setFormData({
            ...formData,
            amenities: {
                ...formData.amenities,
                [amenityName]: floatValue,
            },
        });
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // Prepare the data for API submission
        const area = parseInt(formData.area);
        let price = "";

        if (formData.property_type === "APARTMENT") {
            if (area <= 40) {
                price = "50000";
            } else if (area <= 70) {
                price = "100000";
            } else if (area <= 100) {
                price = "200000";
            } else {
                price = "300000";
            }
        }
        else if (formData.property_type === "HOUSE") {
            if (area <= 50) {
                price = "50000";
            } else if (area <= 100) {
                price = "100000";
            } else if (area <= 150) {
                price = "200000";
            } else {
                price = "300000";
            }
        }

        setFormData({
            ...formData,
            price: price,
        })

        const preparedData = {
            ...formData,
            amenities: Object.keys(formData.amenities).map((key) => ({
                name: key,
                weight: 1.0,
                value: formData.amenities[key],
            })),
        };

        const result = await contentService.calculatePrice(preparedData);

        if (result.success) {
            setResultPrice(result.data.predicted_price);
        } else {
            toast.info("Analysis of this type is not available with a demo licence.");
            setResultPrice(-1);
        }

        setLoading(false);
    };

    return (
        <Box sx={{
            m: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 3px 8px",
            padding: '20px',
            borderRadius: '10px',
        }}>
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" align="center" mb={2}>
                    Analyze Real Estate
                </Typography>
                <Grid container spacing={2}>
                    {/* Transaction Type */}

                    {/* Deal Type Dropdown */}
                    <Grid item xs={6}>
                        <TextField
                            select
                            label="Deal Type"
                            name="transaction_type"
                            value={formData.transaction_type}
                            onChange={handleInputChange}
                            fullWidth
                            SelectProps={{native: true}}
                        >
                            <option value=""/>
                            {Object.keys(TransactionType).map((key) => {
                                const [enumValue, displayName] = TransactionType[key];
                                return (
                                    <option key={key} value={enumValue}>
                                        {displayName}
                                    </option>
                                );
                            })}
                        </TextField>
                    </Grid>

                    {/* Property Type Dropdown */}
                    <Grid item xs={6}>
                        <TextField
                            select
                            label="Property Type"
                            name="property_type"
                            value={formData.property_type}
                            onChange={handleInputChange}
                            fullWidth
                            SelectProps={{native: true}}
                        >
                            <option value=""/>
                            {Object.keys(PropertyType).map((key) => {
                                const [enumValue, displayName] = PropertyType[key];
                                return (
                                    <option key={key} value={enumValue}>
                                        {displayName}
                                    </option>
                                );
                            })}
                        </TextField>
                    </Grid>

                    {/* Area */}
                    <Grid item xs={12}>
                        <TextField
                            label="Area (sqm)"
                            name="area"
                            value={formData.area}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">Select Location on Map</Typography>
                        <MapInput coordinates={formData.location} setCoordinates={handleLocationChange}/>
                    </Grid>

                    {/* Amenities */}
                    <Grid item xs={12}>
                        {Object.keys(sectionDisplayNames).map((sectionKey) => {
                            const section = sectionDisplayNames[sectionKey];
                            const sectionAmenities = section.amenities;

                            return (
                                <div key={sectionKey}>
                                    {/* Section heading */}
                                    <Typography variant="h6" gutterBottom>
                                        {section.displayName}
                                        <Divider width="70%" color="grey"/>
                                    </Typography>

                                    {sectionAmenities.map((amenity) => {
                                        const [amenityName, amenityType, displayName] = amenity;
                                        const isTextFieldRequired = amenityType === AmenityType.MEASURABLE || amenityType === AmenityType.COUNTABLE;

                                        return (
                                            <div key={amenityName}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!!checkedAmenities[amenityName]}
                                                            onChange={(e) => handleAmenityChange(amenityName, amenityType, e)}
                                                        />
                                                    }
                                                    label={displayName}
                                                />
                                                {/* Conditionally show text field if measurable or countable and checked */}
                                                {checkedAmenities[amenityName] && isTextFieldRequired && (
                                                    <TextField
                                                        label={`Enter value:`}
                                                        type="number"
                                                        variant="outlined"
                                                        value={amenityValues[amenityName] || ""}
                                                        onChange={(e) => handleAmenityValueChange(amenityName, e.target.value)}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </Grid>

                    {resultPrice && (

                        <Box style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f5f5f5',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            maxWidth: '300px',
                            margin: '20px auto'
                        }}>
                            <Typography variant="h5" style={{
                                color: '#4caf50',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>
                                {resultPrice === -1 ? "N/A" : "$ " + resultPrice.toFixed(2)}
                            </Typography>
                        </Box>

                    )}

                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button
                                sx={{
                                    fontSize: "1.1em",
                                }}
                                disabled={loading}

                                type="submit" variant="contained" color="primary">
                                Calculate
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default CalculatePage;

/**
 * Service for handling content-related requests.
 * @module contentService
 */

import httpService from "./httpService";
import authHeader from "../utilities/authHeader";
import handleRequest from '../utilities/handleRequest';

import { parseFilterParams } from '../utilities/parsers';

const contentService = {
    getClusters: async (zoom, filters) => {
        const request = httpService.get("/listings/clusters/", {
            params: {
                zoom: zoom,
                ...parseFilterParams(filters)
            },
            headers: authHeader()
        });
        return handleRequest(request);
    },
    
    getTiles: async (geohashes, filters) => {
        const request = httpService.get("/listings/markers/", {
            params: {
                geohashes: geohashes.join(","),
                ...parseFilterParams(filters)
            },
            headers: authHeader()
        });
        return handleRequest(request);
    },

    checkLicenseKeyValidity: async (licenseKey) => {
        const request = httpService.post("/auth/licenses/verify/", { license_key: licenseKey });
        return handleRequest(request);
    },

    calculatePrice: async (data) => {
        const request = httpService.post("/ai/predict/", data);
        return handleRequest(request);
    },

    getProjectedCosts: async (redcatId) => {
        const request = httpService.get(`/projected-costs/${redcatId}/`, { headers: authHeader() });
        return handleRequest(request);
    }

}

export default contentService;

import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Grid,
  MenuItem,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Menu } from "@mui/icons-material";

import Logo from "../../assets/media/logos/logoFullWhite.png";

import LanguageSwitcher from "../tooltips/languageSwitcher";
import NavBarDrawer from "./navBarDrawer";

import { AuthContext } from "../../contexts/authProvider";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation(); // i18n translation hook
  const { isAuthenticated } = useContext(AuthContext); // Auth context

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Sidebar state and toggle function
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const navRoutes = [
    { name: t("navbar.map"), path: "/map", protected: false },
    // {name: t("navbar.about_us"), path: "/about-us", protected: false},
    {
      name: t("navbar.calculate"),
      path: "/calculate",
      protected: true,
    },
  ];

  // View routes which need navbar to be hidden
  const fullScreenViewRoutes = ["/map"];

  // Get the current location and check if it's a full screen view
  const location = useLocation();
  if (fullScreenViewRoutes.includes(location.pathname)) return null;

  // Else, render the navbar
  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            height: {
              xs: "60px", // mobile
              sm: "80px", // tablet
              md: "100px", // desktop and above
            },
            padding: {
              xs: "0 5px", // mobile
              md: "0 20px", // tablet and desktop
            },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Link to="/">
              <Box
                component="div"
                sx={{
                  width: {
                    xs: 180, // mobile
                    sm: 220, // tablet
                    md: 280, // desktop
                  },
                  height: {
                    xs: 50, // mobile
                    sm: 63, // tablet
                    md: 75, // desktop
                  },
                  backgroundImage: `url(${Logo})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Link>
          </Box>

          {/* Nav Links and Language Switcher */}
          {!isSmallScreen && (
            <>
              <Box display="flex" gap={2}>
                {navRoutes.map((route, index) => (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={route.path}
                    sx={{
                      borderRadius: 3,
                      border: "1px solid white",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: {
                        xs: ".5rem",
                        sm: ".8rem",
                        md: "1.2rem",
                      },
                      "&:hover": {
                        color: "#0F1E3C",
                        backgroundColor: "#F5D24B",
                        border: "none",
                      },
                      transition: ".2s",
                    }}
                  >
                    {t(route.name)}
                  </MenuItem>
                ))}
                {isAuthenticated ? (
                  <>
                    <MenuItem
                      component={Link}
                      to="/logout"
                      sx={{
                        borderRadius: 3,
                        border: "1px solid white",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: {
                          xs: ".8rem",
                          sm: "1rem",
                          md: "1.2rem",
                        },
                        "&:hover": {
                          color: "#0F1E3C",
                          backgroundColor: "#F5D24B",
                          border: "none",
                        },
                        transition: ".2s",
                      }}
                    >
                      {t("navbar.logout")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/profile"
                      sx={{
                        borderRadius: 3,
                        border: "1px solid white",
                        color: "#0F1E3C",
                        backgroundColor: "#E6E6E6",
                        fontWeight: "bold",
                        fontSize: {
                          xs: ".8rem",
                          sm: "1rem",
                          md: "1.2rem",
                        },
                        "&:hover": {
                          color: "#0F1E3C",
                          backgroundColor: "#F5D24B",
                          border: "none",
                        },
                        transition: ".2s",
                      }}
                    >
                      {t("navbar.profile")}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      component={Link}
                      to="/login"
                      sx={{
                        borderRadius: 3,
                        border: "1px solid white",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: {
                          xs: ".8rem",
                          sm: "1rem",
                          md: "1.2rem",
                        },
                        "&:hover": {
                          color: "#0F1E3C",
                          backgroundColor: "#F5D24B",
                          border: "none",
                        },
                        transition: ".2s",
                      }}
                    >
                      {t("navbar.login")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/register"
                      sx={{
                        borderRadius: 3,
                        border: "1px solid white",
                        color: "#0F1E3C",
                        backgroundColor: "#E6E6E6",
                        fontWeight: "bold",
                        fontSize: {
                          xs: ".8rem",
                          sm: "1rem",
                          md: "1.2rem",
                        },
                        "&:hover": {
                          color: "#0F1E3C",
                          backgroundColor: "#F5D24B",
                          border: "none",
                        },
                        transition: ".2s",
                      }}
                    >
                      {t("navbar.get_started")}
                    </MenuItem>
                  </>
                )}
              </Box>
              <LanguageSwitcher />
            </>
          )}

          {/* Hamburger Menu */}
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleSidebar}
            >
              <Menu />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <NavBarDrawer
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        navRoutes={navRoutes}
        isAuthenticated={isAuthenticated}
      />
    </>
  );
};

export default Navbar;
